import { $delete, $fetch, $post } from '@/services';

export function load(id) {
  return $fetch(`/cash/fxRateTemplate/${id}`);
}

export function loadAll(data) {
  return $fetch(`/cash/fxRateTemplate`, data);
}

export function fetch(data) {
  return $fetch(`/cash/fxRateTemplate/search`, data);
}

export function post(data) {
  return $post(`/cash/fxRateTemplate`, data);
}

export function remove(id) {
  return $delete(`/cash/fxRateTemplate/${id}`);
}
